import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputBase,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    createStyles,
    fade
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Clear, FilterList } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react";
import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as yup from "yup";
import { Filter } from "../../core/Filter";
import { NAME_PATTERN } from "../../core/constants";
import { DataType } from "../../core/dataType";
import { CDMDocumentType } from "../../core/observables/CDMDocument";
import Entity from "../../core/observables/Entity";
import EntityAttribute, { AssociatedEntity } from "../../core/observables/EntityAttribute";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import ContextMenu, { Position } from "../ContextMenu";
import useConfirm from "../confirm/useConfirm";
import { NegroniIcon } from "../icon/NegronIIcon";
import useProperties from "../properties/useProperties";
import { PaginatedSelect, SelectGroupOption, SelectOption } from "../select/PaginatedSelect";
import useService from "../service/useService";
import CreatableSelect from 'react-select/creatable';
import { Purpose } from "../../core/purpose";

interface EntityAttributeViewProps {
    entityAttributes: EntityAttribute[];
    onSelectEntityAttribute?: (entityAttribute: EntityAttribute | null) => void;
    readOnly?: boolean;
    onAddEntityAttribute: (entityAttribute: EntityAttribute) => void;
    onDeleteEntityAttribute: (entityAttributes: EntityAttribute[]) => Promise<void>;
}

const EntityAttributeViewStyles = makeStyles(theme => createStyles({
    search: {
        display: 'flex',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#DDD1F0",
        '&:hover': {
            backgroundColor: fade("#DDD1F0", 0.2),
        },
        width: '100%',
        margin: '10px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '0.1rem',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const EntityAttributeView: React.FC<EntityAttributeViewProps> = ({
    entityAttributes,
    onSelectEntityAttribute,
    readOnly,
    onAddEntityAttribute,
    onDeleteEntityAttribute,
}) => {
    const classes = EntityAttributeViewStyles();
    const [position, setPosition] = useState<Position | null>(null);
    const [selectedEntityAttribute, setSelectedEntityAttribute] = useState<EntityAttribute[]>([]);
    const [selection, setSelection] = useState<EntityAttribute | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [search, setSearch] = useState('');
    const {addPropertyIgnoreDirtyPredicate, removePropertyIgnoreDirtyPredicate} = useProperties();
    const confirm = useConfirm();
    const [filteredAttributes, setFilteredAttributes] = React.useState<EntityAttribute[]>();

    useEffect(() => {
        addPropertyIgnoreDirtyPredicate(CDMDocumentType.ENTITY_ATTRIBUTE.toString(), source => source.documentType === CDMDocumentType.ENTITY_ATTRIBUTE);
        return () => removePropertyIgnoreDirtyPredicate(CDMDocumentType.ENTITY_ATTRIBUTE.toString());
    }, []);

    const handleSearch = useCallback(({foreignKeyTypeAttribute, associatedEntity}: EntityAttribute): boolean => {
        const searchQuery = search.toLowerCase();
        return foreignKeyTypeAttribute.toLowerCase().includes(searchQuery) ||
            (!!associatedEntity && associatedEntity.name.toLowerCase().includes(searchQuery))
    }, [search]);

    useEffect(() => {
        setFilteredAttributes(entityAttributes.filter(handleSearch))
        setPage(0);
    }, [entityAttributes, handleSearch, page, rowsPerPage]);

    const isSelected = (entityAttribute: EntityAttribute) => selectedEntityAttribute.indexOf(entityAttribute) !== -1;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleClick(entityAttribute: EntityAttribute) {
        if (readOnly) return;

        const selectedIndex = selectedEntityAttribute.indexOf(entityAttribute)
        let newSelected: EntityAttribute[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedEntityAttribute, entityAttribute);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedEntityAttribute.slice(1));
        } else if (selectedIndex === selectedEntityAttribute.length - 1) {
            newSelected = newSelected.concat(selectedEntityAttribute.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedEntityAttribute.slice(0, selectedIndex),
                selectedEntityAttribute.slice(selectedIndex + 1),
            );
        }

        if (onSelectEntityAttribute){
            if (newSelected.length === 1) onSelectEntityAttribute(newSelected[0]);
            else onSelectEntityAttribute(null);
       }

        setSelectedEntityAttribute(newSelected);
    }

    function handleContextMenu(e: React.MouseEvent<HTMLTableRowElement>, entityAttribute: EntityAttribute) {
        setSelection(entityAttribute);
        if (readOnly) return;

        e.preventDefault();
        setPosition({
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    function handleCloseContextMenu() {
        setPosition(null);
    }

    function deleteEntityAttributes(attributes: EntityAttribute[]) {
        confirm({title: 'Delete Entity Attributes?', message: 'Do you really want to delete these entity attributes?'})
            .then(_ => {
                onDeleteEntityAttribute(attributes)
                    .then(_ => {
                        attributes.forEach(entityAttribute => {
                            const deleteIndex = entityAttributes.indexOf(entityAttribute, 0);
                            entityAttributes.splice(deleteIndex, 1);
                        });
                        setSelectedEntityAttribute([]);
                        setSelection(null);
                        if (onSelectEntityAttribute)
                            onSelectEntityAttribute(null);
                        setFilteredAttributes(undefined);
                    });
            });
    }

    function handleDeleteButton() {
        if (selectedEntityAttribute.length > 0) {
            deleteEntityAttributes(selectedEntityAttribute)
        }
    }

    function handleDeleteEntityAttribute() {
        if (selection) {
            deleteEntityAttributes([selection])
        }
    }

    return <div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            {entityAttributes.length > 0 &&
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <NegroniIcon iconClass="search-icon" />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'search'}}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            }
        </div>
        {entityAttributes.length > 0 ? <>
                <TableContainer style={{width: '100%', maxHeight: 540}}>
                    {selectedEntityAttribute.length > 0 && <Toolbar className='table-toolbar' variant='dense'>
                        <Typography color="inherit" variant="subtitle1" component="div">
                            {selectedEntityAttribute.length} Selected
                        </Typography>
                        <Tooltip title="Delete">
                            <IconButton onClick={_ => handleDeleteButton()} color="inherit">
                                 <NegroniIcon iconClass="delete-icon" color="#fff" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>}
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                {!readOnly && <TableCell></TableCell>}
                                <TableCell>Associated Entity</TableCell>
                                <TableCell>Target Entity Attribute</TableCell>
                                <TableCell>Foreign Key Type Attribute</TableCell>
                                <TableCell>Foreign Key Data Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entityAttributes.filter(handleSearch)
                                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                .map((entityAttribute, index) => (
                                    <TableRow
                                        onClick={_ => handleClick(entityAttribute)}
                                        hover
                                        key={index}
                                        style={{cursor: 'pointer'}}
                                        onContextMenu={e => handleContextMenu(e, entityAttribute)}
                                        selected={!!selectedEntityAttribute.find(ea => ea.foreignKeyTypeAttribute === entityAttribute.foreignKeyTypeAttribute)}
                                    >
                                        {!readOnly && <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(entityAttribute)}
                                                inputProps={{'aria-label': 'Select entity attribute'}}
                                            />
                                        </TableCell>}
                                        <TableCell>{entityAttribute?.associatedEntity?.name}</TableCell>
                                        <TableCell>{entityAttribute.associatedEntityTypeAttribute}</TableCell>
                                        <TableCell>{entityAttribute.foreignKeyTypeAttribute}</TableCell>
                                        <TableCell>{entityAttribute.foreignKeyDataType}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50, 100, 200]}
                    component='div'
                    count={filteredAttributes?.length || entityAttributes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    nextIconButtonProps={(filteredAttributes?.length || entityAttributes.length) <= rowsPerPage ? { style: { display: "none" } } : undefined}
                    backIconButtonProps={(filteredAttributes?.length || entityAttributes.length) <= rowsPerPage ? { style: { display: "none" } } : undefined}
                />
                <ContextMenu handleClose={handleCloseContextMenu} position={position}>
                    <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={_ => {
                            handleCloseContextMenu();
                            handleDeleteEntityAttribute();
                        }}
                    >
                        <NegroniIcon iconClass="delete-icon" /> Delete
                    </MenuItem>
                </ContextMenu></> :
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '10px'
            }}>
                No relationships to display
            </div>}
    </div>
};

export interface NewEntityAttributeValue {
    foreignKeyTypeAttribute: string;
    foreignKeyDataType: DataType;
    associatedEntity?: AssociatedEntity;
    targetEntityAttribute: string;
}

export interface NewEntityAttributeDialogProp {
    open: boolean;
    onClose: () => void;
    defaultName?: string;
    onFinish: (value: NewEntityAttributeValue) => Promise<void>;
    entity?: Entity;
    defaultAssociatedEntity?: AssociatedEntity;
    defaultTargetEntityAttribute?: string;
}

const entityAttributeSchemaValidation = (existingForeignKeyTypeAttributes: string[], primaryKey?: string) => {
    return yup.object().shape({
        foreignKeyTypeAttribute: yup.string()
            .label('this')
            .required()
            .min(EntityAttribute.MIN_NAME_LENGTH)
            .max(EntityAttribute.MAX_NAME_LENGTH)
            .matches(NAME_PATTERN, 'this must contain only alphanumeric characters and underscores, with no consecutive underscores allowed.')
            .test("primary-key-check", "Primary key should not be used as foreign key type attribute", value => value !== primaryKey)
            .notOneOf(existingForeignKeyTypeAttributes),
        targetEntityAttribute: yup.string()
            .label('this')
            .required()
            .min(EntityAttribute.MIN_NAME_LENGTH)
            .max(EntityAttribute.MAX_NAME_LENGTH),
        associatedEntity: yup.object()
            .label('this')
            .required(),
    });
};

export const NewEntityAttributeDialog: React.FC<NewEntityAttributeDialogProp> = ({
    open,
    onClose,
    defaultName,
    onFinish,
    entity,
    defaultAssociatedEntity,
    defaultTargetEntityAttribute,
}) => {
    const [associatedEntity, setAssociatedEntity] = useState<AssociatedEntity | undefined>(defaultAssociatedEntity);
    const [targetEntityAttribute, setTargetEntityAttribute] = useState<string>(defaultTargetEntityAttribute || "");
    const [defaultAttributeOptions, setDefaultAttributeOptions] = useState<{ label: string, value: string }[]>([]);
    const [foreignKeyTypeAttribute, setForeignKeyTypeAttribute] = useState<string>(defaultName || "");
    const [foreignKeyDataType, setForeignKeyDataType] = useState<DataType>(DataType.INTEGER);
    const [loadingTargetEntityAttribute, setLoadingTargetEntityAttribute] = useState(false);
    const [filter, setFilter] = useState<Filter>(Filter.RESOLVED);
    const [updateAddEntity, setUpdateAddEntity] = useState(0);
    const {entityService, manifestService} = useService();
    const [nameInput, setNameInput] = useState<HTMLInputElement | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorMessages, setErrorMessages] = useState<{
        foreignKeyTypeAttribute?: string,
        targetEntityAttribute?: string,
        associatedEntity?: string
    }>({});
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [folderFilter, setFolderFilter] = useState(false);
    const [folderFilterValue, setFolderFilterValue] = useState<string>('');
    const [pathFilter, setPathFilter] = useState(false);
    const [pathFilterValue, setPathFilterValue] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const foreignKeyTypeAttributes = entity?.typeAttributes.filter(attrib => attrib.purpose !== Purpose.IDENTIFIED_BY).map(attribute => attribute.name) || [];
    const confirm = useConfirm();

    useEffect(() => {
        handleLoadTargetEntityAttributeOptions()
            .then(setDefaultAttributeOptions);
    }, [associatedEntity]);

    useEffect(() => {
        refreshAddEntity();
    }, [filter]);

    useEffect(() => {
        if (open && nameInput)
            nameInput.select();
    }, [open, nameInput]);

    useEffect(() => {
        if (open) {
            setForeignKeyDataType(DataType.INTEGER);
            setForeignKeyTypeAttribute('');
            setAssociatedEntity(undefined);
            setTargetEntityAttribute('');
            setFilter(Filter.RESOLVED);
            setErrorMessage("");
            setErrorMessages({});
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            setForeignKeyDataType(DataType.INTEGER);
            setForeignKeyTypeAttribute(defaultName || '');
            setTargetEntityAttribute(defaultTargetEntityAttribute || '');
            setAssociatedEntity(defaultAssociatedEntity);
            setErrorMessage("")
            setErrorMessages({});
        }
    }, []);

    useEffect(() => {
        validate();
    }, [foreignKeyTypeAttribute, associatedEntity, targetEntityAttribute]);

    const handleAdvanceFilter = () => setAdvanceFilter(!advanceFilter);

    const hideFilter = () => {
        setAdvanceFilter(false);
    }

    const handleFolderFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFolderFilter(event.target.checked);
        setFolderFilterValue('');
        refreshAddEntity();
    }

    const handlePathFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPathFilter(event.target.checked);
        setPathFilterValue('');
        refreshAddEntity();
    }

    const refreshAddEntity = () => {
        setUpdateAddEntity(updateAddEntity + 1);
    }

    const dataTypeOptions = Object.values(DataType)
        .filter(value => value === DataType.STRING || value === DataType.INTEGER)
        .map(value => ({label: value, value,}))
        .sort((a, b) => a.label.localeCompare(b.label));

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) =>
        setFilter(event.target.checked ? Filter.RESOLVED : Filter.PRIVATE);

    const getCoreRelativePath = (location: string): string => {
        location = location.replace('cdm:/core/', '');
        let splitLocation = location.split('/');
        splitLocation.splice(splitLocation.length - 1, 1);
        return splitLocation.join('/');
    }

    const getEntities = (page: number = 0, limit: number = 20, search: string = ''): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        return entityService.getEntities(filter, limit, page, search, -1, folderFilterValue, pathFilterValue)
            .then(response => {
                return {
                    ...response,
                    result: response.result.map(entity => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    const getEntitiesByManifest = (page: number = 0, limit: number = 20, search: string = ''): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
        return manifestService.getEntitiesByManifest(entity?.targetManifest, entity?.id, limit, page, search)
            .then(response => {
                return {
                    ...response,
                    result: response.result.filter(e => e.id !== entity?.id)
                        .map(entity => ({label: entity.name, value: entity}))
                } as PaginatedResponse<SelectOption[]>;
            });
    };

    const handleInputChange = (value: React.SetStateAction<string>) => {
        setSearch(value);
    };

    const handleFinish = async () => {
        if (foreignKeyTypeAttributes.includes(foreignKeyTypeAttribute))
                await confirm({title: 'Add New Relationship', message: `This will delete the '${foreignKeyTypeAttribute}' attribute. Do you want to continue?`});

        setIsLoading(true);
            onFinish({
                foreignKeyTypeAttribute,
                foreignKeyDataType,
                associatedEntity,
                targetEntityAttribute
            }).then(_ => {
                onClose();
                setErrorMessage('');
            }).catch(setErrorMessage)
                .finally(() => setIsLoading(false));
    }

    const validate = () => {
        const tmpErrorMessages: any = {};
        if (entity) {
            const schema = entityAttributeSchemaValidation(
                entity?.entityAttributes.map(t => t.foreignKeyTypeAttribute), 
                entity.typeAttributes.find(attrib => attrib.purpose === Purpose.IDENTIFIED_BY)?.name
            );

            try {
                schema.validateSync({
                    foreignKeyTypeAttribute,
                    targetEntityAttribute,
                    associatedEntity,
                }, {abortEarly: false});
                setErrorMessages({});
            } catch (e) {
                for (const error of e.inner) {
                    if (!tmpErrorMessages[error.path]) tmpErrorMessages[error.path] = error.message;
                }
                setErrorMessages(tmpErrorMessages);
            }
        }
    }

    const hasErrors = (): boolean => {
        return !!errorMessages.targetEntityAttribute ||
            !!errorMessages.associatedEntity ||
            !!errorMessages.foreignKeyTypeAttribute;
    }

    async function handleLoadTargetEntityAttributeOptions() {
        setTargetEntityAttribute("");
        setLoadingTargetEntityAttribute(true);
        if (!associatedEntity?.id)
            return [];
        try {
            const entity = await entityService.getEntityById(associatedEntity?.id);
            const attributes = entity.typeAttributes.filter(typeAttribute => typeAttribute.name.toLowerCase().includes(search.toLowerCase()))
                .map(typeAttribute => ({label: typeAttribute.name, value: typeAttribute.name}))
                .sort((a, b) => a.label.localeCompare(b.label));
            setLoadingTargetEntityAttribute(false);
            return attributes;
        } catch (_) {
            return [];
        }
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflowY: 'visible'}}
    >
        <DialogTitle id="alert-dialog-title">New Relationship</DialogTitle>
        <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '500px'}}>
            <DialogContentText>
                Define the new attribute for entity to make a relationship.
            </DialogContentText>
            {errorMessage.length > 0 && <Alert severity='error'>
                {errorMessage}
            </Alert>}
            <form action="#">
                <div style={{margin: '5px'}}>
                <FormControl fullWidth variant='filled'>
                    <CreatableSelect
                        isClearable
                        onChange={(option:any) => (option) ? setForeignKeyTypeAttribute(option?.value) : setForeignKeyTypeAttribute("")}
                        options={foreignKeyTypeAttributes.map(attrib => ({ value: attrib , label: attrib}))}
                        placeholder="Foreign Key Type Attribute Name"
                    />
                        <FormHelperText error={!!errorMessages.foreignKeyTypeAttribute && errorMessages.foreignKeyTypeAttribute.length > 0}>
                            {errorMessages.foreignKeyTypeAttribute}
                        </FormHelperText>
                </FormControl>
                </div>
                {entity?.type === 'TEMPLATE' ? <div style={{margin: '5px'}}>
                    <FormControlLabel
                        control={<Checkbox checked={filter === Filter.RESOLVED} onChange={handleFilter}
                                           inputProps={{'aria-label': 'Select custom entities'}}/>}
                        label="Show Core Entities"
                    />
                    <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'row'}} error={!!errorMessages.associatedEntity}>
                        <div style={{width: '100%'}}>
                            <PaginatedSelect
                                getOptions={getEntities}
                                valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                                placeholder='Associated Entity'
                                onValueChanged={setAssociatedEntity}
                                update={updateAddEntity}
                                formatOptionLabel={option => {
                                    return <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {option.label}
                                        {(filter === Filter.RESOLVED && option.value?.path) && <small
                                            style={{color: 'var(--label-1-color)'}}>{getCoreRelativePath(option?.value?.path)}</small>}
                                    </div>;
                                }}
                                hideFilter={hideFilter}
                            />
                        </div>
                        {filter === Filter.RESOLVED ? <Tooltip
                            title={advanceFilter ? 'Hide Advance Filters' : 'Advance Filters'}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{marginLeft: '10px'}}
                                onClick={handleAdvanceFilter}
                            >
                                {advanceFilter ? <Clear/> : <FilterList/>}
                            </Button>
                        </Tooltip> : undefined}
                    </FormControl>
                    <FormHelperText style={{marginLeft: '14px'}} error={!!errorMessages.associatedEntity}>
                        {errorMessages.associatedEntity}
                    </FormHelperText>
                    {advanceFilter && filter === Filter.RESOLVED  ? <FormControl variant='filled' fullWidth={true} style={{display: 'flex', flexDirection: 'column'}}>
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={folderFilter} onChange={handleFolderFilter}
                                                inputProps={{'aria-label': 'Filter by folder'}}/>}
                                label="By Folder"
                            />
                            { folderFilter ? <TextField
                                value={folderFilterValue}
                                onChange={e => {
                                    setFolderFilterValue(e.target.value);
                                    refreshAddEntity();
                                }}
                                type='text'
                                autoFocus
                                fullWidth={true}
                            /> : undefined }
                        </div>
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={pathFilter} onChange={handlePathFilter}
                                                inputProps={{'aria-label': 'Filter by path'}}/>}
                                label="By Path"
                            />
                            { pathFilter ? <TextField
                                value={pathFilterValue}
                                onChange={e => {
                                    setPathFilterValue(e.target.value);
                                    refreshAddEntity();
                                }}
                                type='text'
                                autoFocus
                                fullWidth={true}
                            /> : undefined }
                        </div>
                    </FormControl> : undefined}
                </div> : <div style={{margin: '5px'}}>
                    <FormControl fullWidth variant='filled' error={!!errorMessages.associatedEntity}>
                        <PaginatedSelect
                            getOptions={getEntitiesByManifest}
                            valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                            placeholder='Associated Entity'
                            onValueChanged={setAssociatedEntity}
                            update={updateAddEntity}
                            formatOptionLabel={option => {
                                return <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {option.label}
                                </div>;
                            }}
                        />
                        <FormHelperText error={!!errorMessages.associatedEntity}>
                            {errorMessages.associatedEntity}
                        </FormHelperText>
                    </FormControl>
                </div>}
                <div style={{margin: '5px'}}>
                    <FormControl fullWidth variant='filled' error={!!errorMessages.targetEntityAttribute}>
                        <AsyncSelect
                            value={targetEntityAttribute ? {
                                label: targetEntityAttribute,
                                value: targetEntityAttribute
                            } : undefined}
                            onChange={(option: any) => setTargetEntityAttribute(option?.value)}
                            loadOptions={handleLoadTargetEntityAttributeOptions}
                            defaultOptions={defaultAttributeOptions}
                            isLoading={loadingTargetEntityAttribute}
                            isDisabled={loadingTargetEntityAttribute}
                            isSearchable
                            onInputChange={handleInputChange}
                            placeholder='Target Entity Attribute'
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            menuPlacement='auto'
                            maxMenuHeight={200}
                        />
                        <FormHelperText error={!!errorMessages.targetEntityAttribute}>
                            {errorMessages.targetEntityAttribute}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div style={{margin: '5px'}}>
                    <Select
                        options={dataTypeOptions}
                        isSearchable
                        onChange={(option: any) => setForeignKeyDataType(option?.value)}
                        value={{label: foreignKeyDataType, value: foreignKeyDataType}}
                        placeholder='Foreign Key Data Type'
                        menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                        menuPlacement='auto'
                        maxMenuHeight={200}
                    />
                </div>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
            <Button
                disabled={hasErrors() || isLoading}
                onClick={handleFinish}
                variant='contained'
                color='secondary'
                disableElevation
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>;
}

export default observer(EntityAttributeView);