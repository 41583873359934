import { useEffect } from "react";
import Cookies from "js-cookie";
import useAuth from "./component/auth/useAuth";
import { SESSION_KEY, REFRESH_TOKEN } from "./component/session/SessionProvider";
import useSession from "./component/session/useSession";

const useSessionSync = () => {
    const { generateState, storeState, getAuthState, updateAuthState } = useAuth();
    const {user} = useSession();

    // Retrieve the existing userIds array from localStorage or initialize as empty
    const onboardedUsers: number[] = JSON.parse(localStorage.getItem("onBoardedUsers") || "[]");

    useEffect(() => {
        const authSessionUserSynced = localStorage.getItem("authSessionUserSynced") === "true";

        if (authSessionUserSynced) {
            localStorage.removeItem("authSessionUserSynced");
            return;
        }

        const cookieObject = document.cookie.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
        }, {} as Record<string, string>);

        if (cookieObject["NEGRONI_SESSION"] && cookieObject["NEGRONI_REFRESH_TOKEN"] && (user() && onboardedUsers.includes(user()!.id))) {
            localStorage.setItem("authSessionUserSynced", "true");

            Cookies.remove(SESSION_KEY, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            Cookies.remove(REFRESH_TOKEN, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

            const signInUrl = process.env.REACT_APP_SSO_LOGIN_URL;
            if (signInUrl) {
                const stateToken = generateState();
                storeState(stateToken);
                updateAuthState({ isAuthRunning: false, isAuthenticated: false });
                window.location.href = `${signInUrl}&state=${stateToken}`;
            }
        }

    }, [generateState, storeState, getAuthState, updateAuthState]);
};

export default useSessionSync;
