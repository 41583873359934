import { sortBy } from "lodash";
import { action, observable } from "mobx";
import React from "react";
import * as yup from "yup";
import { CellEditorProps, TextCellEditor } from "../../component/properties/CellEditor";
import { PropertyDescriptor, PropertySource } from "../../component/properties/PropertyDescriptor";
import { AsyncSelectCellEditor, PaginatedSelectCellEditor } from "../../component/properties/SelectCellEditor";
import { NAME_PATTERN } from "../constants";
import { Filter } from "../Filter";
import { EntityService, mapToEntity } from "../services/EntityService";
import PaginatedResponse from "../services/PaginatedResponse";
import CDMDocument, { CDMDocumentType } from "./CDMDocument";
import EntityAttribute from "./EntityAttribute";
import TypeAttribute from "./TypeAttribute";

export enum CreateEntityType {
    TEMPLATE = 'TEMPLATE',
    EXCLUSIVE = 'EXCLUSIVE',
    LONTI = 'LONTI'
}

export default class Entity implements CDMDocument {
    static MIN_NAME_LENGTH = 2;
    static MAX_NAME_LENGTH = 50;
    @observable id: number;
    @observable name: string;
    @observable path: string;
    @observable extendsEntity?: ExtendsEntity;
    @observable description: string = '';
    @observable imports: string[] = [];
    @observable typeAttributes: TypeAttribute[] = [];
    @observable entityAttributes: EntityAttribute[] = [];
    @observable primaryKey?: string;
    @observable isDefault: boolean = true;
    @observable type?: CreateEntityType;
    @observable targetManifest?: number;
    @observable content?: string;
    @observable versions: string[] = [];
    @observable latestVersion: string = '1.0.0';
    @observable currentVersion: string = '1.0.0';
    private _parent: any | undefined;
    readonly documentType = CDMDocumentType.ENTITY;

    constructor(id: number, name: string, path: string, type?: CreateEntityType, targetManifest?: number, primaryKey?: string) {
        this.id = id;
        this.name = name;
        this.path = path;
        this.type = type || CreateEntityType.TEMPLATE;
        this.targetManifest = targetManifest;
        this.primaryKey = primaryKey;
    }

    @action
    addImport(importPath: string) {
        this.imports.push(importPath);
    }

    @action
    addTypeAttribute(typeAttribute: TypeAttribute) {
        typeAttribute.parent = this;
        this.typeAttributes.push(typeAttribute);
    }

    @action
    addEntityAttribute(entityAttribute: EntityAttribute) {
        entityAttribute.parent = this;
        this.entityAttributes.push(entityAttribute);
    }

    addExtendEntity(extendEntity: ExtendsEntity) {
        this.extendsEntity = extendEntity;
    }

    clone(): Entity {
        const entity = new Entity(this.id, this.name, this.path);
        entity.isDefault = this.isDefault;
        entity.imports = this.imports;
        entity.extendsEntity = this.extendsEntity;
        entity.description = this.description;
        entity.typeAttributes = this.typeAttributes.map(ta => ta.clone());
        entity.entityAttributes = this.entityAttributes.map(ea => ea.clone());
        entity.parent = this.parent;
        return entity;
    }

    setPrimaryKey(primaryKey: any) {
        this.primaryKey = primaryKey;
    }

    set parent(parent: any) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    set setTypeAttributes(typeAttributes: TypeAttribute[]) {
        this.typeAttributes = typeAttributes
    }

}

export interface ExtendsEntity {
    id: number;
    name: string;
    path: string;
}

export class EntityPropertySource implements PropertySource {

    private entity: Entity;
    private readonly descriptors: PropertyDescriptor[] = [];
    documentType = CDMDocumentType.ENTITY;
    private readonly entityService: EntityService;

    constructor(entity: Entity, entityService: EntityService) {
        this.entity = entity
        this.entityService = entityService;

        async function handleEntityVersions() {
            let versions: { label: string, value: string }[] = []
            try {
                await entityService.getEntityVersionsById(entity.id)
                    .then(entity => {
                        entity.entityVersions.forEach((version:any) => {
                            versions.push({
                                label: version.VERSION,
                                value: version.VERSION
                            })
                        })
                        return sortBy(versions, "label");
                    });
                return versions
            } catch (_) {
                return [];
            }
        }

        const isReadonly = entity.currentVersion !== entity.latestVersion || (entity.type === CreateEntityType.TEMPLATE || entity.type === CreateEntityType.LONTI);
        
        this.descriptors.push({
            readOnly: isReadonly || entity.isDefault,
            defaultValue: this.entity.name,
            displayName: 'Name',
            description: 'Name of the entity',
            hasDefaultValue: true,
            name: 'name',
            value: this.entity.name,
            target: this.entity,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Name'
                                                                            description='Name of the entity'/>,
            validate: value => yup.string()
                .required()
                .min(2)
                .max(120)
                .matches(NAME_PATTERN, 'this must contain only alphanumeric characters and underscores, with no consecutive underscores allowed.')
                .validate(value),
        }, {
            readOnly: isReadonly || entity.isDefault,
            defaultValue: this.entity.description,
            displayName: 'Description',
            description: 'Description of the entity',
            hasDefaultValue: true,
            name: 'description',
            value: this.entity.description,
            target: this.entity,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Description'
                                                                            description='Description of the entity'/>,
            validate: _ => undefined,
        }, {
            readOnly: true,
            defaultValue: this.entity.extendsEntity,
            displayName: 'Extends Entity',
            description: 'The extended entity',
            hasDefaultValue: true,
            name: 'extendsEntity',
            value: this.entity.extendsEntity,
            displayValue: this.entity.extendsEntity?.name || 'None',
            target: this.entity,
            getCellEditor: (props: CellEditorProps<any>) => <PaginatedSelectCellEditor
                {...props}
                getOptions={(page, limit, search, cancelTokenSource) => {
                    return entityService.getEntities(Filter.ALL, limit, page, search, -1, undefined, undefined, cancelTokenSource?.token)
                        .then(response => ({
                            ...response,
                            result: response.result.map(entity => ({
                                label: entity.name, value: entity
                            }))
                        } as PaginatedResponse<{ label: string, value: any }[]>));
                }}
                onValueChanged={_ => {
                }}
                valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                isClearable={true}
            />,
            validate: _ => undefined,
        }, 
        {
            readOnly: (entity.type === CreateEntityType.TEMPLATE || entity.type === CreateEntityType.LONTI),
            defaultValue: this.entity.latestVersion,
            displayName: 'Version',
            description: 'The current version of the entity',
            hasDefaultValue: true,
            name: 'latestVersion',
            value: this.entity.latestVersion || '1.0.0',
            displayValue: this.entity.latestVersion || '1.0.0',
            target: this.entity,
            getCellEditor: (props: CellEditorProps<any>) => <AsyncSelectCellEditor
            {...props}
            loadOptions={handleEntityVersions}
        />,
        validate: _ => undefined
        });
    }

    get title(): string {
        return 'Entity';
    }

    get iconClass(): string {
        return '';
    }

    dispose(): void {
    }

    getPropertyDescriptors(): PropertyDescriptor[] {
        return this.descriptors;
    }

    async setProperty(name: string, newValue: any): Promise<void> {
        switch (name) {
            case 'name':
                const oldName = this.entity.name;
                this.entity.name = newValue as string;
                this.entity.path.replace(oldName, newValue);
                break;
            case 'description':
                this.entity.description = newValue as string;
                break;
            case 'extendsEntity':
                this.entity.extendsEntity = newValue as ExtendsEntity;
                break;
            case 'latestVersion':
                const result = await this.entityService.getEntityVersion(this.entity.id, newValue);
                const entity = JSON.parse((result).entity.CONTENT);
                Object.assign(entity, {
                    id: this.entity.id,
                    content: result.entity.CONTENT,
                    parent: this.entity.parent
                });
                
                Object.assign(this.entity, mapToEntity(entity, entity.typeAttributes, entity.entityAttributes, newValue));

                break;
        }
    }

    getModel(): CDMDocument {
        return this.entity;
    }

}

export const mapToAdminCreateEntity = (org: string, entity: Entity, type: CreateEntityType, targetManifest?: number): AdminCreateEntity => {
    return {
        org: org,
        entity: {
            name: entity.name,
            targetManifestId: targetManifest,
            extendsEntity: entity.extendsEntity ? {
                id: entity.extendsEntity.id,
                name: entity.extendsEntity.name,
                path: entity.extendsEntity.path,
            } : undefined,
            description: entity.description,
            referenceImportPaths: entity.imports,
            typeAttributes: entity.typeAttributes.map(ta => ({
                name: ta.name,
                dataType: ta.dataType,
                description: ta.description,
                purpose: ta.purpose,
                isNullable: ta.nullable,
                appliedTraits: [],
                choices: ta?.choices || [],
                maxLength: ta.maxLength
            })),
            entityAttributes: entity.entityAttributes.map(ea => ({
                associatedEntityId: ea.associatedEntity?.id,
                associatedEntityName: ea.associatedEntity?.name,
                associatedEntityTypeAttribute: ea.associatedEntityTypeAttribute,
                associatedEntityPath: ea.associatedEntity?.path,
                description: ea.description,
                traitReference: ea.traitReference,
                simple: ea.simple.toString(),
                foreignKeyAttribute: {
                    typeAttributeName: ea.foreignKeyTypeAttribute,
                    purpose: ea.foreignKeyPurpose,
                    dataType: ea.foreignKeyDataType,
                },
            })),
        },
        type: type
    }
}

export const mapToCreateEntity = (entity: Entity, type: CreateEntityType, targetManifest?: number): CreateEntity => {
    return {
        entity: {
            name: entity.name,
            extendsEntity: entity.extendsEntity ? {
                id: entity.extendsEntity.id,
                name: entity.extendsEntity.name,
                path: entity.extendsEntity.path,
            } : undefined,
            description: entity.description,
            typeAttributes: entity.typeAttributes.map(ta => ({
                name: ta.name,
                dataType: ta.dataType,
                description: ta.description,
                purpose: ta.purpose,
                isNullable: ta.nullable,
                appliedTraits: [],
                choices: ta.choices || [],
                maxLength: ta.maxLength
            })),
            entityAttributes: entity.entityAttributes.map(ea => ({
                associatedEntityId: ea.associatedEntity?.id,
                associatedEntityName: ea.associatedEntity?.name,
                associatedEntityTypeAttribute: ea.associatedEntityTypeAttribute,
                associatedEntityPath: ea.associatedEntity?.path,
                description: ea.description,
                traitReference: ea.traitReference,
                simple: ea.simple.toString(),
                foreignKeyAttribute: {
                    typeAttributeName: ea.foreignKeyTypeAttribute,
                    purpose: ea.foreignKeyPurpose,
                    dataType: ea.foreignKeyDataType,
                },
            })),
        },
        referenceImportPaths: entity.imports,
        traits: [],
        targetManifestId: targetManifest,
        type: type,
    };
}

export const mapToCreateEntityWizard = (entity: Entity, type: CreateEntityType, targetManifest?: number, duplicateEntityId?: number, version?: string): CreateEntityWizard => {
    if (!entity.entityAttributes) entity.entityAttributes =[];
    return {
        entity: {
            name: entity.name,
            extendsEntity: entity.extendsEntity ? {
                id: entity.extendsEntity.id,
                name: entity.extendsEntity.name,
                path: entity.extendsEntity.path,
            } : undefined,
            description: entity.description,
            typeAttributes: entity.typeAttributes.map(ta => ({
                name: ta.name,
                dataType: ta.dataType,
                description: ta.description,
                purpose: ta.purpose,
                isNullable: ta.nullable,
                appliedTraits: [],
                maxLength: ta.maxLength
            })),
            entityAttributes: entity.entityAttributes.map(ea => ({
                associatedEntityId: ea.associatedEntity?.id,
                associatedEntityName: ea.associatedEntity?.name,
                associatedEntityTypeAttribute: ea.associatedEntityTypeAttribute,
                associatedEntityPath: ea.associatedEntity?.path,
                description: ea.description,
                traitReference: ea.traitReference,
                simple: ea.simple.toString(),
                foreignKeyAttribute: {
                    typeAttributeName: ea.foreignKeyTypeAttribute,
                    purpose: ea.foreignKeyPurpose,
                    dataType: ea.foreignKeyDataType,
                },
            })),
        },
        referenceImportPaths: entity.imports,
        traits: [],
        targetManifestId: targetManifest,
        duplicateEntityId: duplicateEntityId,
        type: type,
        version: version,
    };
}

export const mapToUpdateEntity = (entity: Entity): UpdateEntity => {
    return {...mapToCreateEntity(entity, entity?.type || CreateEntityType.TEMPLATE, entity?.targetManifest || undefined), type: entity?.type || CreateEntityType.TEMPLATE, version: entity?.currentVersion};
}


/**
 * The request object to create entity for admin side
 */
export interface AdminCreateEntity {
    org: string,
    entity: {
        name: string,
        targetManifestId?: number
        extendsEntity?: {
            id: number,
            name: string,
            path: string,
        },
        description: string,
        referenceImportPaths: string[];
        typeAttributes: {
            name: string,
            purpose: string,
            dataType: string,
            description: string,
            appliedTraits: {
                traitReference: string,
                arguments: {
                    name: string,
                    value: string,
                }[]
            }[],
            isNullable: boolean,
            maxLength ?: number
        }[],
        entityAttributes?: {
            associatedEntityId?: number,
            associatedEntityName?: string,
            associatedEntityTypeAttribute: string,
            associatedEntityPath?: string,
            description: string,
            traitReference: string,
            simple: string,
            foreignKeyAttribute: {
                typeAttributeName: string,
                purpose: string,
                dataType: string,
            },
        }[],
    };
    type?: CreateEntityType;
}

export interface UpdateEntityContent {
    content: string;
}

/**
 * The request object to create entity
 */
export interface CreateEntity {
    referenceImportPaths: string[];
    traits: {
        name: string,
        extendsTrait: string,
        params: {
            name: string,
            dataTypeReference: string,
            defaultValue: string,
        }[],
    }[];
    entity: {
        name: string,
        extendsEntity?: {
            id: number,
            name: string,
            path: string,
        },
        description: string,
        typeAttributes: {
            name: string,
            purpose: string,
            dataType: string,
            description: string,
            appliedTraits: {
                traitReference: string,
                arguments: {
                    name: string,
                    value: string,
                }[]
            }[],
            isNullable: boolean,
            choices: string[],
            maxLength?: number
        }[],
        entityAttributes?: {
            associatedEntityId?: number,
            associatedEntityName?: string,
            associatedEntityTypeAttribute: string,
            associatedEntityPath?: string,
            description: string,
            traitReference: string,
            simple: string,
            foreignKeyAttribute: {
                typeAttributeName: string,
                purpose: string,
                dataType: string,
            },
        }[],
    };
    targetManifestId?: number
    type?: CreateEntityType;
}

export interface CreateEntityWizard {
    referenceImportPaths: string[];
    traits: {
        name: string,
        extendsTrait: string,
        params: {
            name: string,
            dataTypeReference: string,
            defaultValue: string,
        }[],
    }[];
    entity: {
        name: string,
        extendsEntity?: {
            id: number,
            name: string,
            path: string,
        },
        description: string,
        typeAttributes: {
            name: string,
            purpose: string,
            dataType: string,
            description: string,
            appliedTraits: {
                traitReference: string,
                arguments: {
                    name: string,
                    value: string,
                }[]
            }[],
            isNullable: boolean,
            maxLength ?: number
        }[],
        entityAttributes?: {
            associatedEntityId?: number,
            associatedEntityName?: string,
            associatedEntityTypeAttribute: string,
            associatedEntityPath?: string,
            description: string,
            traitReference: string,
            simple: string,
            foreignKeyAttribute: {
                typeAttributeName: string,
                purpose: string,
                dataType: string,
            },
        }[],
    };
    targetManifestId?: number;
    duplicateEntityId?: number;
    type?: CreateEntityType;
    version?: string;
}

export interface UpdateEntity extends CreateEntity {
    version?: string;
}