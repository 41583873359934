import { Avatar, Button, createStyles, List, ListItem, ListItemAvatar, ListItemText, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from '@material-ui/icons/Launch';
import { observer } from "mobx-react";
import { Scrollbars } from 'rc-scrollbars';
import React from "react";
import { useHistory } from "react-router-dom";
import { CDMManager } from "../../core/CDMManager";
import useAuth from "../auth/useAuth";
import useSession from "../session/useSession";
import useSessionSync from "../../useSessionSync";


const orgStyle = makeStyles((theme: Theme) =>
    createStyles({
        orgText: {
            fontSize: '3em !important',
            paddingLeft: 10,
        },
        largeAvatar: {
            width: theme.spacing(9),
            height: theme.spacing(9),
            backgroundImage: 'linear-gradient(315deg,var(--dark-secondary-color) 0,var(--data-heading-color) 50%)',
        },
    }),
);

const SelectOrgLogin: React.FC = () => {

    const history = useHistory();
    const { setOrg, user } = useSession();
    const _user = user();
    const orgs = JSON.parse(_user!.negroniPermission).sort((a:any, b:any) =>  parseInt(b.ng) -parseInt(a.ng));
    const classes = orgStyle();
    const orgAcronym = (orgName: string): string => orgName?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
    const { getAuthState, logout } = useAuth();

    const handleSwitchOrg = (orgName: string, orgCode: string) => {
        setOrg(orgCode, orgName);
        if (localStorage.getItem(CDMManager.NEGRONI_LAST_VISIT) === 'Default') {
            const currentSelected = JSON.parse(localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) || '{}');
            if (!currentSelected?.isDefault) {
                localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
            }
        }
        window.history.pushState('', '', '/');
        history.push("/");
    };

    const handleLogout = () => {
        const isAuthenticated = getAuthState()?.isAuthenticated
        logout();
        const signOutUrl = process.env.REACT_APP_SSO_LOGOUT_URL;
        if (signOutUrl && isAuthenticated) {
            window.location.href = signOutUrl
        }
    }

    return <div style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        background: "#1D1826",
        color: "#fff",
    }}>
        <div style={{ padding: "2.5rem 5rem", maxWidth: "800px", margin: "0 auto" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                    <Avatar src={_user?.avatar || '/images/default-avatar.png'} />
                    <p>{`${_user?.fullname}(${_user?.email})`}</p>
                </div>
                <p onClick={handleLogout} style={{ color: "red", textDecoration: "none", cursor: "pointer" }}>Logout</p>
            </div>
            <hr style={{ width: "100%", margin: "0.5rem 0", borderWidth: "thin" }} />

            <p style={{ color: "yellow", margin: "2rem 0" }}>
                {orgs?.every((org: any) => !!!(parseInt(org.ng))) ? " Sorry, you are not a member of a Negroni subscription on any of these organizations. Please contact the administrator of your organization to request to be added to a Negroni subscription. Once done, logout and login again." : ""}
            </p>

            <h1>Organisations</h1>
            <Scrollbars style={{ height: 500 }}>
                <List>
                    {orgs?.map((org: any) => (
                        <ListItem key={org.oc} style={{ margin: "0.5rem 0", background: "#DDD1F0" }}>
                            <ListItemAvatar>
                                <Avatar className={classes.largeAvatar}>
                                    {orgAcronym(org.on)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                className={classes.orgText}
                                primary={org.on}
                            />
                            <Button
                                onClick={_ => {
                                    handleSwitchOrg(org.on, org.oc)
                                }}
                                color="secondary"
                                style={{ margin: '10px' }}
                                size='small'
                                variant='contained'
                                disabled={!!!parseInt(org.ng)}
                            >
                                <LaunchIcon style={{ paddingRight: '5px' }} /> {!!!parseInt(org.ng) ? 'No subscription' : 'Select'}
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Scrollbars>
        </div>
    </div>
}

export default observer(SelectOrgLogin);