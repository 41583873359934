import React, {useEffect, useState} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from "@material-ui/core";
import Manifest from "../../../core/observables/Manifest";
import { NAME_PATTERN } from "../../../core/constants";
import * as yup from "yup";
import { NewManifest } from "../../manifest/ManifestView";

interface CreateManifestByOrgDialogProps {
    orgCode: string;
    orgName: string;
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    onFinish: (orgCode: string, manifest: Manifest) => Promise<string | undefined>;
}

export const CreateManifestByOrgDialog: React.FC<CreateManifestByOrgDialogProps> = (
    {
        orgCode,
        orgName,
        open,
        onClose,
        onCancel,
        onFinish,
    }
) => {
    const [manifestName, setManifestName] = useState('new_manifest');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (open) {
            setManifestName('new_manifest');
            validateName();
        }
    }, [open]);

    useEffect(() => validateName(), [manifestName]);

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(Manifest.MIN_NAME_LENGTH)
                .max(Manifest.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must contain only alphanumeric characters and underscores, with no consecutive underscores allowed.')
                .validateSync(manifestName);
            setErrorMessage('');
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    function onFinishCreateManifest() {
        const manifest = new Manifest(-1, manifestName, '');
        setIsLoading(true)
        onFinish(orgCode, manifest)
            .then(error => {
                setIsLoading(false);
                setErrorMessage('');
                if (error && error.length > 0) {
                    setErrorMessage(error);
                } else {
                    onClose();
                    setManifestName('new_manifest');
                }
            })
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>Create Manifest</DialogTitle>
        <DialogContent dividers style={{minHeight: '200px', minWidth: '450px'}}>
            <Typography>{"Create manifest for " + orgName}</Typography>
            <NewManifest
                manifestName={manifestName}
                setManifestName={setManifestName}
                error={errorMessage}
                onSubmit={() => {
                    if (!isLoading)
                        onFinishCreateManifest();
                }}
                autoFocus={true}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={_ => onCancel()}>
                Cancel
            </Button>
            <Button
                type='submit'
                disabled={isLoading || errorMessage.length > 0}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={onFinishCreateManifest}
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>
} 