import React, { FC, useState, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import useQuery from "../router/useQuery";
import useAuth from "./useAuth";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import Cookies from "js-cookie";
import { LAST_NEGRONI_USER, LAST_NEGRONI_ORG_CODE, LAST_NEGRONI_ORG_NAME } from "../session/SessionProvider";
import useSession from "../session/useSession";

export const Login: FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const { generateState, storeState, getState, getAuthState, updateAuthState, ssoSignIn } = useAuth();
    const params = new URLSearchParams(window.location.search);
    const authCode = query.get('code') || params.get('code');
    const authState = query.get('state') || params.get('state');
    const [textProcess, setTextProcess] = useState('Redirecting...');
    let isAuthRunning = useRef(false);
    const { setOrg } = useSession();

    useMemo(() => {
        if (authCode && !isAuthRunning.current) {
            isAuthRunning.current = true;
            updateAuthState({ isAuthRunning: true, isAuthenticated: false });
            setTextProcess('Signing In...');
            if (authState) {
                const sessionState = getState();
                if (authState !== sessionState) {
                    history.push(`/error?code=${401}&error=${'State Mismatch'}&message=${'Could not find state session or incorrect state value'}`);
                    return;
                }
            } else {
                history.push(`/error?code=${401}&error=${'State Mismatch'}&message=${'State not found on the URL parameter'}`);
                return;
            }

            ssoSignIn(authCode).then(user => {
                setLoading(false);
                updateAuthState({ isAuthRunning: false, isAuthenticated: true });
                window.history.pushState('', '', '/');

                const lastUser = Cookies.get(LAST_NEGRONI_USER);
                const lastOrgCode = Cookies.get(LAST_NEGRONI_ORG_CODE);
                const lastOrgName = Cookies.get(LAST_NEGRONI_ORG_NAME);

                const permissions = JSON.parse(user.permissions);
                const permission = permissions.find((p: any) => p.oc === Cookies.get(LAST_NEGRONI_ORG_CODE))?.ng;
                const validOrgs = permissions.filter((p: any) => !!parseInt(p.ng));

                Cookies.set("NEGRONI_SESSION", user.authToken.id_token, {
                    expires: 1 / 24, // 1 hour
                });

                Cookies.set(
                    "NEGRONI_REFRESH_TOKEN",
                    user.authToken.refresh_token,
                    { expires: 30 }
                );

                // Check if last user and org match, or set new org if available
                if (lastUser && Cookies.get(LAST_NEGRONI_USER) === user.username && lastOrgCode && lastOrgName && !!parseInt(permission)) {
                    setOrg(lastOrgCode, lastOrgName);
                    history.push("/");
                } else if (validOrgs.length === 1) {
                    setOrg(validOrgs[0].oc, validOrgs[0].on);
                    history.push("/");
                } else {
                    history.push('/org');
                }
            }).catch(e => {
                setLoading(false);
                updateAuthState({ isAuthRunning: false, isAuthenticated: false });
                history.push(`/error?code=${401}&error=${'Invalid Request'}&message=${e}`);
            });
        } else {
            const signInUrl = process.env.REACT_APP_SSO_LOGIN_URL;
            if (signInUrl) {
                const stateToken = generateState();
                storeState(stateToken);
                window.location.href = signInUrl + '&state=' + stateToken;
            }
        }
    }, [authCode, authState, generateState, getState, history, setOrg, ssoSignIn, storeState, updateAuthState]);

    return (
        <>
            {loading && (
                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                    <Grid style={{ marginBottom: '20px' }}>
                        <CircularProgress size={75} />
                    </Grid>
                    <Grid>
                        <Typography variant="h5">
                            {textProcess}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default observer(Login);
