import React, { useEffect } from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";
import useAuth from "../auth/useAuth";
import Cookies from "js-cookie";
import { SESSION_KEY, REFRESH_TOKEN } from "../session/SessionProvider";
import useSession from "../session/useSession";

const ProtectedRoute: React.FC<RouteProps> = (props) => {
    const { generateState, storeState, getAuthState, updateAuthState } = useAuth();
    const { isLoggedIn } = useSession();




    return (
        <Route
            {...props}
            children={undefined}
            render={() => {
                return isLoggedIn() ? props.children : <Redirect to="/sso/login" />;
            }}
        />
    );
};

export default ProtectedRoute;
