import React, { FC, useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Toolbar,
  Tooltip,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Avatar,
  Typography,
  Divider,
} from "@material-ui/core";
import useSession from "../session/useSession";
import { NegroniIcon } from "../icon/NegronIIcon";
import { CDMManager } from "../../core/CDMManager";
import useContent from "../content/useContent";
import useConfirm from "../confirm/useConfirm";
import { TriggerType } from "../content/ContentProvider";
import { useHistory } from "react-router-dom";
import SelectOrganisationDialog from "../organisation/SelectOrganisationDialog";
import { GuidedToursType } from "../../App";

const toolbarStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: "calc(100%)",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "calc(100%)",
      borderRight: 0,

      height: 48,
      backgroundColor: "#2B263A",
    },
    toolBar: {
      minHeight: 36,
      paddingLeft: 0,
    },
    navigatorIcon: {
      minWidth: 35,
    },
    imageCircle: {
      borderRadius: "50%",
      width: 20,
    },
  })
);

interface MainToolbarProps {
  manifestSize: number;
  openManifestWizard: () => void;
  openEntityWizard: () => void;
  openExportDatabaseStructure: () => void;
  openExportCdmSchema: () => void;
  openExportDataModel: () => void;
  openExportPackage: () => void;
  openImportWizard: () => void;
  onStartOnBoarding: () => void;
  onGuidedTours: () => void;
  onDrawerToggle?: () => void;
  joyrideRunning?: boolean;
  joyrideIndex?: number;
  indexTrigger?: (index: number) => void;
  guidedTourType?: GuidedToursType;
}

export const MainToolbar: FC<MainToolbarProps> = ({
  manifestSize,
  openManifestWizard,
  openEntityWizard,
  openExportDatabaseStructure,
  openExportCdmSchema,
  openExportPackage,
  openImportWizard,
  openExportDataModel,
  onStartOnBoarding, onGuidedTours, onDrawerToggle,
  joyrideRunning,
  joyrideIndex,
  indexTrigger,
  guidedTourType
}) => {
  const classes = toolbarStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(
    null
  );
  const [openSelectOrgDialog, setOpenSelectOrgDialog] = useState(false);
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [avatar, setAvatar] = useState<string>("/images/default-avatar.png");
  const { user } = useSession();
  const { setTriggerType, dirty } = useContent();
  const confirm = useConfirm();
  const history = useHistory();

  const showMenu = (joyrideRunning && (joyrideIndex === 1) && guidedTourType !== GuidedToursType.NONE)

  useEffect(() => {
    if (joyrideIndex && guidedTourType !== GuidedToursType.NONE) {
      if (joyrideIndex === 1) {
        setAnchorEl(document.getElementById(("negroni-toolbar-manifest-wizard")))
      }
    }
  }, [joyrideIndex])

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if(indexTrigger && joyrideRunning) indexTrigger(1);
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
};

  const handleProfileClose = () => {
    setAnchorElProfile(null);
  };
  const handleLogoutClick = () => {
    setAnchorElProfile(null);
    const confirmOptions = dirty
      ? {
          message:
            "There are unsaved changes, are you sure you want to logout?",
          title: "Logout",
        }
      : { message: "Are you sure you want to logout?", title: "Logout" };
    confirm(confirmOptions).then(() => {
      if (dirty) {
        setTriggerType(TriggerType.LOGOUT);
      }
      localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
      localStorage.removeItem(CDMManager.NEGRONI_LAST_VISIT);
      history.push("/sso/logout");
    });
  };

  const handleOrganizationClick = () => {
    setOpenSelectOrgDialog(true);
    setAnchorElProfile(null);
  };

  const handleOrganizationClose = () => {
    setOpenSelectOrgDialog(false);
  };

  const handleOnboarding = () => {
    onStartOnBoarding()
    setAnchorElProfile(null);
  };

  const handleGuidedTours = () => {
    onGuidedTours()
    setAnchorElProfile(null);
  };

  return (
    <>
      <Drawer
        id="negroni-toolbar-drawer"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        elevation={0}
        anchor="left"
      >
        <Toolbar id="negroni-toolbar" className={classes.toolBar}>
          <IconButton id="negroni-toolbar-icon"  disabled>
            <NegroniIcon
              iconClass="negroni-icon"
              size="large"
              color="#FACB2E"
            />
          </IconButton>
          <Tooltip title="New">
            <IconButton
              id="negroni-toolbar-manifest-wizard"
              color="primary"
              onClick={handleMenuClick}
            >
              <NegroniIcon iconClass="add-square-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Generate Database Schema">
            <IconButton
              id="negroni-toolbar-generate-database"
              disabled={manifestSize <= 0}
              style={
                manifestSize <= 0
                  ? { color: "var(--gray-color)" }
                  : { color: "var(--primary-color)" }
              }
              onClick={openExportDatabaseStructure}
            >
              <NegroniIcon iconClass="storage-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Generate CDM Schema">
            <IconButton
              id="negroni-toolbar-generate-cdm"
              disabled={manifestSize <= 0}
              style={
                manifestSize <= 0
                  ? { color: "var(--gray-color)" }
                  : { color: "var(--primary-color)" }
              }
              onClick={openExportCdmSchema}
            >
              <NegroniIcon iconClass="description-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Generate Martini Package">
            <IconButton
              id="negroni-toolbar-generate-martini"
              disabled={manifestSize <= 0}
              style={
                manifestSize <= 0
                  ? { color: "var(--gray-color)" }
                  : { color: "var(--primary-color)" }
              }
              onClick={openExportPackage}
            >
              <NegroniIcon iconClass="wrench-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Generate Data Model">
            <IconButton
              id="negroni-toolbar-generate-model"
              disabled={manifestSize <= 0}
              style={
                manifestSize <= 0
                  ? { color: "var(--gray-color)" }
                  : { color: "var(--primary-color)" }
              }
              onClick={openExportDataModel}
            >
              <NegroniIcon iconClass="model-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Import">
            <IconButton
              id="negroni-toolbar-import-wizard"
              color="primary"
              onClick={openImportWizard}
            >
              <NegroniIcon iconClass="import-icon" color="#DDF1F0" />
            </IconButton>
          </Tooltip>
          <IconButton
            id="negroni-profile-button"
            aria-controls="main-menu"
            aria-haspopup="true"
            onClick={handleProfileClick}
            style={{ marginLeft: "auto", maxHeight: "48px" }}
          >
            <Tooltip title="Your Profile">
              <div className={classes.imageCircle}>
                {avatarLoaded ? (
                  <img
                    id="negroni-profile"
                    src={avatar}
                    alt="User"
                    className={classes.imageCircle}
                  />
                ) : (
                  <CircularProgress size={20} />
                )}
                <img
                  style={{ display: "none" }}
                  alt="User"
                  onLoad={(_) => {
                    const _user = user();
                    if (_user && _user?.avatar) {
                      setAvatarLoaded(true);
                      setAvatar(_user.avatar);
                    }
                  }}
                  src={user?.()?.avatar}
                  onError={(_) => {
                    setAvatarLoaded(true);
                    setAvatar("/images/default-avatar.png");
                  }}
                />
              </div>
            </Tooltip>
          </IconButton>
        </Toolbar>
      </Drawer>
      <Menu
        id="new-menu"
        anchorEl={showMenu ? document.getElementById("negroni-toolbar-manifest-wizard") : anchorEl}
        open={showMenu ? true : Boolean(anchorEl)}
        keepMounted={showMenu}
        onClose={handleMenuClose}
        onBlur={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        disableEnforceFocus
      >
        <MenuItem id="open-manifest-wizard" onClick={openManifestWizard}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="assignment-icon" />
          </ListItemIcon>
          <ListItemText primary="Manifest" />
        </MenuItem>
        <MenuItem id="open-entity-wizard" onClick={openEntityWizard}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="description-icon" />
          </ListItemIcon>
          <ListItemText primary="Entity" />
        </MenuItem>
      </Menu>
      <Menu
        id="main-menu"
        anchorEl={anchorElProfile}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleProfileClose}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem disabled={true} style={{ opacity: 1 }}>
          <ListItemIcon
            className={classes.navigatorIcon}
            style={{ padding: "10px" }}
          >
            <Avatar src={avatar} />
          </ListItemIcon>
          <ListItemText
            primary={user()?.fullname}
            secondary={
              <>
                <Typography
                  style={{ display: "inline" }}
                  component="span"
                  variant="body2"
                >
                  {user()?.email}
                </Typography>
              </>
            }
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOrganizationClick}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="public-icon" />
          </ListItemIcon>
          <ListItemText
            primary={`Organizations (${user()?.organisationName})`}
          />
        </MenuItem>
        <MenuItem onClick={handleOnboarding}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="info-icon" />
          </ListItemIcon>
          <ListItemText primary="Onboarding" />
        </MenuItem>
        <MenuItem onClick={handleGuidedTours}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="flag-icon" />
          </ListItemIcon>
          <ListItemText primary="Guided Tours" />
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon className={classes.navigatorIcon}>
            <NegroniIcon iconClass="exit-to-app-icon" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      <SelectOrganisationDialog
        open={openSelectOrgDialog}
        onClose={handleOrganizationClose}
      />
    </>
  );
};

export default MainToolbar;
