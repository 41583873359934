import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Paper,
    Tab,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    createStyles,
    makeStyles
} from "@material-ui/core";
import { Alert, Skeleton, TabContext, TabList, TabPanel } from "@material-ui/lab";
import axios from "axios";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { NAME_PATTERN } from "../../core/constants";
import { DataType } from "../../core/dataType";
import DocumentItem from "../../core/observables/DocumentItem";
import Entity, { CreateEntityType, ExtendsEntity, UpdateEntityContent } from "../../core/observables/Entity";
import EntityAttribute from "../../core/observables/EntityAttribute";
import Manifest from "../../core/observables/Manifest";
import TypeAttribute from "../../core/observables/TypeAttribute";
import { TypeAttributeFilter } from "../../core/services/EntityService";
import { _noop } from "../../util";
import { LoadingProgress } from "../LoadingProgress";
import useConfirm from "../confirm/useConfirm";
import useContent from "../content/useContent";
import { NegroniIcon } from "../icon/NegronIIcon";
import useProperties from "../properties/useProperties";
import useService from "../service/useService";
import useSession from "../session/useSession";
import AddEntityToManifestDialog from "./AddEntityToManifestDialog";
import ManageEntityVersionDialog from "./ManageEntityVersionDialog";
import EntityAttributeView from "./EntitiyAttributeView";
import { EntityJsonView } from "./EntityJsonView";
import { ExtendEntityDialog } from "./ExtendEntityDialog";
import TypeAttributeView from "./TypeAttributeView";

const entityViewStyles = makeStyles(() =>
    createStyles({
        toolBar: {
            minHeight: 36,
            paddingLeft: 0,
        }
    })
);

interface EntityViewProps {
    manifest?: Manifest;
    entity: Entity;
    setSelection: (property: any) => void;
    onAddToManifest: (manifest: DocumentItem, entity: Entity) => Promise<string | undefined>;
    onAddTypeAttribute: (typeAttribute: TypeAttribute, entity: Entity) => void;
    onAddEntityAttribute: (entityAttribute: EntityAttribute, entity: Entity) => void;
    onDeleteEntity: (entity: Entity) => void;
    onDeleteEntityAttribute: (entityAttributes: EntityAttribute[]) => Promise<void>;
    onSave: (entity: Entity) => Promise<string | undefined>;
    onChanges: (entity: Entity) => void;
    onReload: (entity: Entity) => Promise<void>;
    onUpdateEntityContent: (content: UpdateEntityContent, refresh: boolean) => Promise<string | undefined>;
    viewMode: string;
    entityOrg?: string;
    orgLabel?: string;
}

enum TabValue {
    TYPE_ATTRIBUTES = 'Type Attributes',
    ENTITY_ATTRIBUTES = 'Relationships',
    EXTENDED_ATTRIBUTES = 'Extended Attributes',
    JSON_VIEW = 'Json',
}

export const EntityView: React.FC<EntityViewProps> = ({
    manifest,
    entity,
    setSelection,
    onAddToManifest,
    onAddTypeAttribute,
    onAddEntityAttribute,
    onDeleteEntity,
    onDeleteEntityAttribute,
    onSave,
    onChanges,
    onUpdateEntityContent,
    viewMode,
    entityOrg,
    orgLabel,
}) => {
    const classes = entityViewStyles();
    const [tab, setTab] = useState<TabValue>(TabValue.TYPE_ATTRIBUTES);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [extendedTypeAttributes, setExtendedTypeAttributes] = useState<TypeAttribute[] | null>(null);
    const {entityService, manifestService, adminOrgService} = useService();
    const isMounted = useRef(false);
    const [openAddToManifestDialog, setOpenAddToManifestDialog] = React.useState(false);
    const [openExtendEntityDialog, setOpenExtendEntityDialog] = React.useState(false);
    const {setDirty, dirty} = useContent();
    const {setProperty} = useProperties();
    const confirm = useConfirm();
    const [defaultVersionOptions, setDefaultVersionOptions] = useState<{ label: string, value: string }[]>([]);
    const [loadingVersion, setLoadingVersion]  = useState(false);
    const [targetVersion, setTargetVersion] = useState<string>('');
    const [typeAttributes, setTypeAttributes] = useState<TypeAttribute[]>([]);
    const [entityAttributes, setEntityAttributes] = useState<EntityAttribute[]>(entity.entityAttributes);
    const [openAddEntityVersion, setOpenAddEntityVersion] = useState(false);
    const [versions, setVersions] = useState<{ version: string, name: string, description: string }[]>([]);
    const [isReadonly, setIsReadonly]  = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    })

    useEffect(() => {
        setSelection(entity);
        setTypeAttributes(entity.typeAttributes);
        handleVersions()
            .then(setDefaultVersionOptions)
    }, [entity]);

    useEffect(() => {
        setTypeAttributes(entity.typeAttributes);
    }, [entity.typeAttributes]);

    useEffect(() => {
        setEntityAttributes(entity.entityAttributes);
    }, [entity.entityAttributes]);

    useEffect(() => {        
        setIsReadonly(entity.currentVersion !== entity.latestVersion || (entity.type === CreateEntityType.TEMPLATE || entity.type === CreateEntityType.LONTI)) 
    }, [entity.currentVersion, entity.latestVersion]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if(viewMode === 'Admin') {
            entityService.getEntityById(entity.id, TypeAttributeFilter.ALL, targetVersion, cancelToken.token)
            .then(e => {
                setTypeAttributes(e.typeAttributes)
            });
        }
    }, [targetVersion]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (tab === TabValue.EXTENDED_ATTRIBUTES && entity.extendsEntity) {
            setExtendedTypeAttributes(null);
            entityService.getEntityById(entity.extendsEntity.id, TypeAttributeFilter.ALL, undefined, cancelToken.token)
                .then(e => {
                    const filteredTypeAttributes = e.typeAttributes.filter(ta => !entity.typeAttributes.map(_ta => _ta.name)
                        .find(name => name === ta.name));
                    filteredTypeAttributes.forEach(typeAttr => {
                        typeAttr.isDefault = true;
                    });
                    setExtendedTypeAttributes(filteredTypeAttributes);
                });
        }
        return () => cancelToken.cancel();
    }, [tab]);

    const entityName = <span>{entity.name} <small
        style={{color: 'var(--light-color)'}}>{entity.extendsEntity ? ': ' + entity.extendsEntity.name : undefined}</small></span>;

    const handleSave = (entity: Entity) => {
        setIsLoading(true);
        onSave(entity)
            .then(error => {
                if (isMounted.current) {
                    setIsLoading(false);
                    if (error && error.length > 0)
                        setErrorMessage(error);
                }
            });
    };

    const onDeleteExtendsEntity = (entity: Entity) => {
        setSelection(entity);
        onChanges(entity);
    };

    const onApply = (content: string) => {
        entity.content = content;
    }

    function handleAddToManifest() {
        setOpenAddToManifestDialog(true);
    }

    function handleCloseDialog() {
        setOpenAddToManifestDialog(false);
    }

    const handleDeleteEntity = () => {
        confirm({message: 'Do you really want to delete the entity?', title: 'Delete Entity'})
            .then(() => onDeleteEntity(entity));
    };

    const handleDeleteExtendsEntity = () => {
        confirm({
            message: `Do you really want to delete the extended '${entity?.extendsEntity?.name}' entity`,
            title: 'Delete Extended Entity'
        }).then(() => {
            entity.extendsEntity = undefined;
            onDeleteExtendsEntity(entity);
        });
    };

    const handleExtendEntity = () => {
        setOpenExtendEntityDialog(true);
    }

    const handleCloseExtendEntity = () => {
        setOpenExtendEntityDialog(false);
    }

    const handleApplyExtendEntity = (extendEntity: ExtendsEntity | undefined) => {
        entity.extendsEntity = extendEntity;
        setProperty(entity);
        setDirty(true);
    }

    const handleAddEntityVersion = (version: string) : Promise<string | undefined> => {
        return entityService.createEntityVersion(entity.id, version)
            .then(() => {
                handleVersions()
                    .then(setDefaultVersionOptions)
                setTargetVersion(version)
                enqueueSnackbar('Successfully created entity version.', {variant: 'success'});
            })
            .catch(error => error || 'Failed to create entity version');
    }

    const handleOpenCreateEntityVersion = async () => {
        await handleVersions();
        setOpenAddEntityVersion(true);
    }

    const handleCloseCreateEntityVersion = () => {
        setOpenAddEntityVersion(false);
    }

    async function handleVersions() {
        let versions: { label: string, value: string }[] = []
        setLoadingVersion(true)
        try {
            await entityService.getEntityVersionsById(entity.id)
                .then(entity => {
                    setVersions(entity.entityVersions.map((v:any) => {
                        const content = JSON.parse(v.CONTENT)
                        return {
                            version: v.VERSION,
                            name: content.name,
                            description: content.description
                        }
                    })
                    );
                    entity.entityVersions.forEach((version:any) => {
                        versions.push({
                            label: version.VERSION,
                            value: version.VERSION
                        })
                    })
                    setLoadingVersion(false)
                    return versions;
                });
            return versions
        } catch (_) {
            return [];
        }
    }

    return <div>
        <Grid container>
            <Grid item xs={12}>
                <Toolbar className={classes.toolBar}>
                    <Tooltip title='Save'>
                        <IconButton
                            disabled={isReadonly || !dirty}
                            style={{color: !dirty ? 'var(--gray-color)' : 'var(--light-secondary-color)'}}
                            onClick={_ => handleSave(entity)}
                        >
                            { isLoading ? <CircularProgress style={{color: 'var(--light-secondary-color)'}} size={23}/> :  <NegroniIcon iconClass="save-icon" />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={tab === TabValue.TYPE_ATTRIBUTES ? 'Add Attribute' : 'Add Relationship'}>
                        <IconButton
                            color='primary'
                            onClick={_ => {
                                tab === TabValue.TYPE_ATTRIBUTES ?
                                    onAddTypeAttribute(new TypeAttribute('new_type_attribute', DataType.STRING, entity.isDefault), entity) :
                                    onAddEntityAttribute(new EntityAttribute(undefined, '', '', '', DataType.INTEGER, entity.isDefault), entity);
                            }}
                            disabled={isReadonly}
                        >
                             <NegroniIcon iconClass="add-circle-icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Add To Manifest'>
                        <IconButton
                            color='primary'
                            onClick={handleAddToManifest}
                            disabled={isReadonly}
                        >
                             <NegroniIcon iconClass="new-manifest-icon" />
                        </IconButton>
                    </Tooltip>
                    { entity?.extendsEntity ?
                        <Tooltip title='Delete Extend Entity'>
                            <IconButton
                                style={{color: 'var(--light-error-color)'}}
                                onClick={handleDeleteExtendsEntity}
                                disabled={isReadonly}
                            >
                                <NegroniIcon iconClass="extend-entity-icon" />
                            </IconButton>
                        </Tooltip> : <Tooltip title='Extend Entity'>
                            <IconButton
                                color='primary'
                                onClick={handleExtendEntity}
                                disabled={isReadonly}
                            >
                                <NegroniIcon iconClass="extend-entity-icon" />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title='Manage Versions'>
                        <IconButton
                            color='primary'
                            onClick={handleOpenCreateEntityVersion}
                            disabled={(entity.type === CreateEntityType.TEMPLATE || entity.type === CreateEntityType.LONTI)}
                        >
                             <NegroniIcon iconClass="filter-9-plus-icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Entity'>
                        <IconButton
                            style={{color: 'var(--light-error-color)'}}
                            onClick={handleDeleteEntity}
                            disabled={(entity.type === CreateEntityType.TEMPLATE || entity.type === CreateEntityType.LONTI)}
                        >
                             <NegroniIcon iconClass="delete-icon" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <div style={{flexGrow: 1}}>
                    <h3 style={{margin: '5px', cursor: 'pointer'}}
                        onClick={_ => setSelection(entity)}>{entityName}</h3>
                    <p style={{margin: '5px', wordWrap: 'normal'}}>{entity.description}</p>
                </div>
            </Grid>
            {(errorMessage && errorMessage.length > 0) &&
            <Grid item xs={12}>
                <Alert severity={'error'}>{errorMessage}</Alert>
            </Grid>}
        </Grid>
        <AddEntityToManifestDialog
            entity={entity}
            open={openAddToManifestDialog}
            onClose={handleCloseDialog}
            onFinish={onAddToManifest}
            manifestService={manifestService}
            adminOrgService={adminOrgService}
            viewMode={viewMode}
            manifestOrg={viewMode === 'Admin' ? entityOrg : 'TORO'}
            orgLabel={orgLabel}
        />
        <ExtendEntityDialog
            entity={entity}
            open={openExtendEntityDialog}
            onClose={handleCloseExtendEntity}
            onFinish={handleApplyExtendEntity}
            viewMode={viewMode}
            manifestOrg={viewMode === 'Admin' ? entityOrg : 'TORO'}
            orgLabel={orgLabel}
        />
        <ManageEntityVersionDialog
            entity={entity}
            open={openAddEntityVersion}
            onClose={handleCloseCreateEntityVersion}
            onFinish={handleAddEntityVersion}
            versions={versions}
            entityService={entityService}
        />
        {manifest ? (
            <Breadcrumbs style={{margin: '5px', textAlign: 'center'}}>
                <Link href='#' style={{color: 'var(--light-color)'}}>{manifest.name}</Link>
                <Typography>{entity.name}</Typography>
            </Breadcrumbs>) : undefined}
        <TabContext value={tab}>
            <Paper elevation={0} square>
                <TabList
                    value={tab}
                    onChange={(e, value) => {
                        setTab(value);
                    }}
                    variant='fullWidth'
                >
                    {Object.values(TabValue)
                        .filter(tab => entity.extendsEntity || (!entity.extendsEntity && tab !== TabValue.EXTENDED_ATTRIBUTES))
                        .filter(tab => viewMode === 'Admin' || (!(viewMode === 'Admin') && tab !== TabValue.JSON_VIEW))
                        .map((tab, index) => <Tab key={index} value={tab} label={tab}/>)}
                </TabList>
            </Paper>
            <TabPanel value={TabValue.TYPE_ATTRIBUTES}>
                <TypeAttributeView
                    typeAttributes={typeAttributes}
                    onSelectTypeAttribute={typeAttribute => setSelection(typeAttribute || null)}
                    readOnly={isReadonly || entity.isDefault}
                    onAddTypeAttribute={typeAttribute => onAddTypeAttribute(typeAttribute, entity)}
                    onDeleteTypeAttribute={() => onChanges(entity)}
                    primaryKey={entity.primaryKey}
                />
            </TabPanel>
            <TabPanel value={TabValue.ENTITY_ATTRIBUTES}>
                <EntityAttributeView
                    entityAttributes={entityAttributes}
                    readOnly={isReadonly || entity.isDefault}
                    onSelectEntityAttribute={entityAttribute => setSelection(entityAttribute || null)}
                    onAddEntityAttribute={entityAttribute => onAddEntityAttribute(entityAttribute, entity)}
                    onDeleteEntityAttribute={onDeleteEntityAttribute}
                />
            </TabPanel>
            {entity.extendsEntity && <TabPanel value={TabValue.EXTENDED_ATTRIBUTES}>
                {extendedTypeAttributes ? <>
                    <Alert style={{marginTop: '10px'}} severity='info'>
                        All type attributes from <strong>{entity.extendsEntity.name}</strong> entity
                    </Alert>
                    <TypeAttributeView
                        typeAttributes={extendedTypeAttributes}
                        onSelectTypeAttribute={typeAttribute => setSelection(typeAttribute || null)}
                        readOnly={true}
                        onAddTypeAttribute={_noop}
                        onDeleteTypeAttribute={_noop}
                    />
                </> : <LoadingProgress/>}
            </TabPanel>}
            { viewMode === 'Admin' && <TabPanel value={TabValue.JSON_VIEW}>
                <EntityJsonView
                    entity={entity}
                    onUpdateEntityContent={onUpdateEntityContent}
                    onApply={onApply}
                />
            </TabPanel> }
        </TabContext>
    </div>;
}

export const EntityViewSkeleton = () => {
    return <div style={{padding: '10px'}}>
        <Skeleton animation='pulse' height={30} width={200}/>
        <Skeleton animation='pulse' height={60}/>
        <div style={{display: 'flex'}}>
            <div style={{paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={40} width={120}/>
            </div>
            <div style={{paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={40} width={120}/>
            </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Skeleton animation='pulse' height={40} width={100}/>
            <div style={{paddingLeft: '10px'}}>
                <Skeleton variant='rect' animation='pulse' height={35} width={40}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={40}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={40}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
    </div>;
}

interface NewEntityProps {
    setEntityName: (entityName: string) => void;
    entityName: string;
    error: string | null | undefined;
    onSubmit?: () => void;
    autoFocus?: boolean;
    setNameInput?: (input: HTMLInputElement) => void;
}

export const NewEntity: React.FC<NewEntityProps> = ({
    entityName,
    setEntityName,
    error,
    onSubmit,
    autoFocus,
    setNameInput
}) => {
    return <div>
        <form onSubmit={e => {
            e.preventDefault();
            if (onSubmit)
                onSubmit();
        }}>
            <TextField
                autoFocus={autoFocus}
                inputRef={setNameInput}
                margin="dense"
                id="entityName"
                label="Entity Name"
                fullWidth
                color='primary'
                variant='filled'
                value={entityName}
                onChange={e => setEntityName(e.target.value)}
                error={!!error}
                helperText={error}
            />
        </form>
    </div>
}

interface NewEntityDialogProps {
    open: boolean;
    onClose: () => void;
    onFinish: (entity: Entity) => Promise<string | undefined>;
    onCancel: () => void;
    defaultEntityName: string;
}

export const CreateEntityDialog: React.FC<NewEntityDialogProps> = ({
    defaultEntityName,
    open,
    onClose,
    onCancel,
    onFinish
}) => {
    const [entity, setEntity] = useState<Entity>(new Entity(-1, defaultEntityName, ''));
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [nameInput, setNameInput] = useState<HTMLInputElement>();

    useEffect(() => {
        if (open) {
            setEntity(new Entity(-1, defaultEntityName, ''));
            validateName();
        }
    }, [open]);

    useEffect(() => {
        if (open && nameInput)
            nameInput.select();
    }, [open, nameInput]);

    useEffect(() => validateName(), [entity.name]);

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(Entity.MIN_NAME_LENGTH)
                .max(Entity.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must contain only alphanumeric characters and underscores, with no consecutive underscores allowed.')
                .validateSync(entity.name);
            setErrorMessage('');
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    const handleCreateEntity = () => {
        setIsLoading(true);
        onFinish(entity)
            .then(error => {
                setIsLoading(false);
                setErrorMessage('');
                if (error && error.length > 0) {
                    setErrorMessage(error);
                } else {
                    onClose();
                    setEntity(new Entity(-1, defaultEntityName, ''));
                }
            });
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle id="form-dialog-title">Create Global Entity</DialogTitle>
        <DialogContent dividers style={{minHeight: '200px', minWidth: '450px'}}>
            <div>Create a custom global entity</div>
            <NewEntity
                entityName={entity.name}
                setEntityName={entityName => setEntity(new Entity(-1, entityName, ''))}
                error={errorMessage}
                onSubmit={() => {
                    if (!isLoading)
                        handleCreateEntity();
                }}
                autoFocus={true}
                setNameInput={setNameInput}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={_ => onCancel()}>
                Cancel
            </Button>
            <Button
                disabled={isLoading || errorMessage.length > 0}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={handleCreateEntity}
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>
}

interface EntityViewMenuProps {
    entity: Entity;
    onAddToManifest: (manifest: DocumentItem, entity: Entity) => Promise<string | undefined>;
    onDeleteEntity: (entity: Entity) => void;
    onDeleteExtendsEntity: (entity: Entity) => void;
    onSave: (entity: Entity) => void;
    loading: boolean;
    onReload: (entity: Entity) => Promise<void>;
    viewMode: string;
    entityOrg?: string;
    orgLabel?: string;
}

export const EntityViewMenu: React.FC<EntityViewMenuProps> = observer(({
    entity,
    onAddToManifest,
    onDeleteEntity,
    onDeleteExtendsEntity,
    onSave,
    loading,
    onReload,
    viewMode,
    entityOrg,
    orgLabel,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [openAddToManifestDialog, setOpenAddToManifestDialog] = React.useState(false);
    const [openExtendEntityDialog, setOpenExtendEntityDialog] = React.useState(false);
    const {manifestService, adminOrgService} = useService();
    const confirm = useConfirm();
    const {setDirty, dirty} = useContent();
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const {user} = useSession();
    const {setProperty} = useProperties();

    function handleOpen() {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleNavigatorMenuClose(event: React.MouseEvent<EventTarget>) {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open)
            anchorRef.current!.focus();

        prevOpen.current = open;
    }, [open]);

    function handleAddToManifest() {
        setOpenAddToManifestDialog(true);
        setOpen(false);
    }

    function handleCloseDialog() {
        setOpenAddToManifestDialog(false);
    }

    const handleDeleteEntity = () => {
        setOpen(false);
        confirm({message: 'Do you really want to delete the entity?', title: 'Delete Entity'})
            .then(() => onDeleteEntity(entity));
    };
    
    const handleDeleteExtendsEntity = () => {
        setOpen(false);
        confirm({
            message: `Do you really want to delete the extended '${entity?.extendsEntity?.name}' entity`,
            title: 'Delete Extended Entity'
        }).then(() => {
            entity.extendsEntity = undefined;
            onDeleteExtendsEntity(entity);
        });
    };

    const handleExtendEntity = () => {
        setOpen(false);
        setOpenExtendEntityDialog(true);
    }

    const handleCloseExtendEntity = () => {
        setOpenExtendEntityDialog(false);
    }

    const handleApplyExtendEntity = (extendEntity: ExtendsEntity | undefined) => {
        entity.extendsEntity = extendEntity;
        setProperty(entity);
        setDirty(true);
    }

    return (
        <>
            {entity.isDefault && user()?.isAdmin && <Tooltip title='Reload Entity'>
                <Button
                    variant='contained'
                    size='small'
                    color='secondary'
                    disableElevation
                    style={{margin: '10px'}}
                    onClick={_ => {
                        setIsReloading(true);
                        onReload(entity)
                            .finally(() => setIsReloading(false));
                    }}
                    disabled={isReloading}
                >
                    {isReloading ? <CircularProgress size={23}/> : <NegroniIcon iconClass="refresh-icon" />}
                </Button>
            </Tooltip>}
            <div hidden={!dirty}>
                <Button
                    onClick={_ => onSave(entity)}
                    style={{margin: '10px', marginRight: 0}}
                    size='small'
                    variant='contained'
                    disableElevation
                    color='secondary'
                    title='Save Entity'
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={22}/>}
                >
                    <NegroniIcon iconClass="save-icon" />
                </Button>
            </div>
            {(entity.type === CreateEntityType.TEMPLATE || !entity.isDefault) &&
            <Button
                style={{margin: '10px'}}
                ref={anchorRef}
                onClick={handleOpen}
                size='small'
                variant='contained'
                disableElevation
            ><NegroniIcon iconClass="settings-icon" /></Button>}
            <Menu
                anchorEl={anchorRef.current}
                open={open}
                onClose={handleNavigatorMenuClose}
            >
                {entity.type === CreateEntityType.TEMPLATE &&
                <MenuItem onClick={handleAddToManifest}><NegroniIcon iconClass="assignment-icon" /> Add to Manifest</MenuItem>}
                {entity.extendsEntity ?
                <MenuItem onClick={handleDeleteExtendsEntity} className='delete'> <NegroniIcon iconClass="delete-icon" /> Delete Extends Entity</MenuItem> :
                <MenuItem onClick={handleExtendEntity}> <NegroniIcon iconClass="extend-entity-icon" /> Extends Entity</MenuItem>}
                {!entity.isDefault &&
                <MenuItem onClick={handleDeleteEntity} className='delete'> <NegroniIcon iconClass="delete-icon" /> Delete Entity</MenuItem>}
            </Menu>
            <AddEntityToManifestDialog
                entity={entity}
                open={openAddToManifestDialog}
                onClose={handleCloseDialog}
                onFinish={onAddToManifest}
                manifestService={manifestService}
                adminOrgService={adminOrgService}
                viewMode={viewMode}
                manifestOrg={viewMode === 'Admin' ? entityOrg : 'TORO'}
                orgLabel={orgLabel}
            />
            <ExtendEntityDialog
                entity={entity}
                open={openExtendEntityDialog}
                onClose={handleCloseExtendEntity}
                onFinish={handleApplyExtendEntity}
                viewMode={viewMode}
                manifestOrg={viewMode === 'Admin' ? entityOrg : 'TORO'}
                orgLabel={orgLabel}
            />
        </>
    );
})

export default observer(EntityView);