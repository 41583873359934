import React, {useEffect, useRef, useState} from "react";
import { observer } from "mobx-react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    Avatar,
    Icon,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import { NegroniIcon } from "../icon/NegronIIcon";
import { ManifestService } from "../../core/services/ManifestService";
import { Filter } from "../../core/Filter";

interface GuidedToursProp {
    open: boolean;
    onClose: () => void;
    startTour: (type: string) => void;
    manifestService: ManifestService
}

const guidedTourStyle = makeStyles((theme: Theme) =>
    createStyles({
        guidedTourText: {
            fontSize: '10em !important',
            paddingLeft: 20,
            maxWidth: 450
        },
        largeAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundImage: 'linear-gradient(315deg,var(--dark-secondary-color) 0,var(--data-heading-color) 50%)',
        },
    }),
);

const GuidedTours: React.FC<GuidedToursProp> = ({open, onClose, startTour, manifestService}) => {
    const classes = guidedTourStyle();
    
    const [hasManifest, setHasManifest] = useState(false);

    useEffect(() => {
      // Check if manifests are available in the service
      const checkManifests = async () => {
        try {
          const manifests = await  manifestService
          .getManifests(Filter.PRIVATE, undefined, 1, 0, ""); 
          setHasManifest(manifests && manifests.totalElements > 0);
        } catch (error) {
          console.error("Error checking manifests:", error);
          setHasManifest(false); // Handle the error gracefully
        }
      };
  
      checkManifests();
    }, [manifestService, open]);

    return <Dialog
        open={open}
        onClose={onClose}
        maxWidth='xl'
    >
        <DialogTitle>Guided Tours</DialogTitle>
        <DialogContent dividers>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <NegroniIcon iconClass="new-manifest-icon" size="large" color="#ddd1f0" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.guidedTourText}
                        primary='Create Manifest Tour'
                    />
                    <Button
                        onClick={_ => {
                            startTour('CreateManifestTour')
                            onClose()
                        }}
                        style={{margin: '10px'}}
                        size='small'
                        color='secondary'
                        variant='contained'
                    >
                        <LaunchIcon style={{paddingRight: '10px'}}/> Start
                    </Button>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <NegroniIcon iconClass="add-square-icon" size="large" color="#ddd1f0" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.guidedTourText}
                        primary='Create Entity Tour'
                        secondary={!hasManifest ? "A manifest is required to start the tour. You can either create one now or start the 'Create Manifest Tour' for step-by-step guidance." : ''  }
                    />
                    <Button
                        onClick={_ => {
                            startTour('CreateEntityTour')
                            onClose()
                        }}
                        style={{margin: '10px'}}
                        size='small'
                        color='secondary'
                        variant='contained'
                        disabled={!hasManifest}
                    >
                    
                        <LaunchIcon style={{paddingRight: '10px'}}/> Start
                    </Button>
                </ListItem>
            </List>
        </DialogContent>
    </Dialog>
}

export default observer(GuidedTours)