import React, {FC} from "react";
import AuthContext, {AuthContextValue} from "./AuthContext";
import HttpClient from "axios";
import Cookies from "js-cookie";
import config from "../../config";
import useSession from "../session/useSession";
import AuthState from "../auth/AuthState";
import { SESSION_KEY, REFRESH_TOKEN, USER_KEY } from "../session/SessionProvider";
import { uuid } from "uuidv4";

const BASE = `/${config.api_version}/cdm`;

const AuthProvider: FC = ({children}) => {
    const {setUser} = useSession();

    const logout = () => {
        Cookies.remove(SESSION_KEY);
        Cookies.remove(REFRESH_TOKEN);
        localStorage.removeItem('authState');
        localStorage.removeItem('state');
        localStorage.removeItem(USER_KEY);
        setUser(undefined);
    };

    const generateState = (): string => {
        return uuid();
    }

    const storeState = (stateToken: string) => {
        localStorage.setItem('state', stateToken);
    }

    const getState = (): string => {
        return localStorage.getItem('state') || '';
    }

    const getAuthState = (): AuthState => {
        let authState = localStorage.getItem('authState');
        if(!authState) {
            return { isAuthRunning: false, isAuthenticated: false}
        }

        return JSON.parse(authState)
    }

    const updateAuthState = (authState: AuthState) => {
        localStorage.setItem('authState', JSON.stringify(authState));
    }

    const ssoSignIn = (code: string) => {
        return HttpClient.post(`${BASE}/sso/signin?code=${code}`)
          .then((res) => {
            const user = res.data.eccUser;
            user.avatar = res.data.memberDetails.avatar;
            user.fullname = res.data.memberDetails.fullname;
            user.id = res.data.memberDetails.memberId;
            setUser(user);
            return {
              id: user.id,
              username: user.username,
              permissions: user.negroniPermission,
              authToken: {
                id_token: user.id_token,
                expires_in: user.expires_in,
                refresh_token: user.refresh_token,
              },
            };
          })
          .catch((e) => {
            throw new Error(e);
          });
    };

    const ssoRefreshToken = (refreshToken: string) => {
        return HttpClient.post(`${BASE}/sso/refresh?refreshToken=${refreshToken}`)
            .then(res => {
               const user = res.data.eccOAuthToken;
                Cookies.set("NEGRONI_SESSION", user.id_token, {
                    expires: 1/24,
                  });
  
                  Cookies.set(
                    "NEGRONI_REFRESH_TOKEN",
                    user.refresh_token,
                    { expires: 30 }
                  );
                return user;
            })
    }

    return <>
        <AuthContext.Provider value={{
            logout,
            generateState,
            storeState,
            getState,
            getAuthState,
            updateAuthState,
            ssoSignIn,
            ssoRefreshToken,
        } as AuthContextValue}>
            {children}
        </AuthContext.Provider>
    </>
}

export default AuthProvider;