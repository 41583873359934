import { createContext } from "react";
import AuthState from "./AuthState";

export interface AuthContextValue {
  logout: () => void;
  generateState: () => string;
  storeState: (stateToken: string) => void;
  getState: () => string;
  getAuthState: () => AuthState;
  updateAuthState: (authState: AuthState) => void;
  ssoSignIn: (code: string) => Promise<{
    id: number;
    username: string;
    permissions: string;
    authToken: {
      id_token: string;
      expires_in: number;
      refresh_token: string;
    };
  }>;
  ssoRefreshToken: (refreshToken: string) => Promise<{
    id_token: string;
    expires_in: number;
    refresh_token: string;
  }>;
}

export default createContext<AuthContextValue>({} as AuthContextValue);
