import React, {useEffect, useState} from "react";
import Entity from "../../../core/observables/Entity";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from "@material-ui/core";
import { NewEntity } from "../../entity/EntityView";
import * as yup from "yup";
import {NAME_PATTERN} from "../../../core/constants";
import TypeAttribute from "../../../core/observables/TypeAttribute";
import { DataType } from "../../../core/dataType";
import { Purpose } from "../../../core/purpose";

interface CreateEntityByOrgDialogProps {
    orgCode: string;
    orgName: string;
    open: boolean;
    defaultEntityName: string;
    onClose: () => void;
    onCancel: () => void;
    onFinish: (orgCode: string, entity: Entity) => Promise<string | undefined>;
}

export const CreateEntityByOrgDialog: React.FC<CreateEntityByOrgDialogProps> = ({
    orgCode,
    orgName,
    open,
    defaultEntityName,
    onClose,
    onCancel,
    onFinish,
}) => {
    const [entity, setEntity] = useState<Entity>(new Entity(-1, defaultEntityName, ''));
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [nameInput, setNameInput] = useState<HTMLInputElement>();

    useEffect(() => {
        if (open) {
            setEntity(new Entity(-1, defaultEntityName, ''));
            validateName();
        }
    }, [open]);

    useEffect(() => {
        if (open && nameInput)
            nameInput.select();
    }, [open, nameInput]);

    useEffect(() => validateName(), [entity.name]);

    function onFinishCreateEntity() {
        const primaryKey = new TypeAttribute('id', DataType.INTEGER);
        primaryKey.purpose = Purpose.IDENTIFIED_BY;
        entity.typeAttributes = [primaryKey];
        setIsLoading(true);
        onFinish(orgCode, entity)
            .then(error => {
                setIsLoading(false);
                setErrorMessage('');
                if (error && error.length > 0) {
                    setErrorMessage(error);
                } else {
                    onClose();
                    setEntity(new Entity(-1, defaultEntityName, ''));
                }
            })
    }

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(Entity.MIN_NAME_LENGTH)
                .max(Entity.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must contain only alphanumeric characters and underscores, with no consecutive underscores allowed.')
                .validateSync(entity.name);
            setErrorMessage('');
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>Create Entity</DialogTitle>
        <DialogContent dividers style={{minHeight: '200px', minWidth: '450px'}}>
            <Typography>{"Create entity for " + orgName}</Typography>
            <NewEntity
                entityName={entity.name}
                setEntityName={entityName => setEntity(new Entity(-1, entityName, ''))}
                error={errorMessage}
                onSubmit={() => {
                    if (!isLoading)
                        onFinishCreateEntity();
                }}
                autoFocus={true}
                setNameInput={setNameInput}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={_ => onCancel()}>
                Cancel
            </Button>
            <Button
                disabled={isLoading || errorMessage.length > 0}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={onFinishCreateEntity}
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>
}

export default CreateEntityByOrgDialog